<template>
  <div class="home homecontainer">
    <HeadImage
    title1="Umzüge und Entrümpelungen "
    title2="in München und ganz Bayern"
    subtitle="Professionelle Umzugsfirma mit Kompetenz und Erfahrung für Privat und Gewerbe."
    />
    <div class="container py-4" style="min-height: 50vh;">
      <div class="row d-flex">
      <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard text="center">
        <MDBCardBody v-once  class="flex-column d-flex">
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Rufen Sie uns direkt an</MDBCardTitle>
          <MDBCardText v-once>
            <p>Nicht lange zögern. Rufen Sie uns an und wir können gerne einen Besichtigungstermin vereinbaren und alle wichtigen Details besprechen.</p>
            <a class="h2" href="tel:+4915730438580" title="Anrufen"  @click="PhoneCallClicked">015730438580</a>
          </MDBCardText>
          <MDBBtn class="mt-auto" tag="a" href="tel:+4915730438580" title="Anrufen" @click="PhoneCallClicked" color="primary" size="lg"><MDBIcon icon="phone" size="1x" /> <span> </span>015730438580</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </div>
    <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard text="center">
        <MDBCardBody v-once  class="flex-column d-flex">
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Angebot online anfordern</MDBCardTitle>
          <MDBCardText v-once>
            <p>Sie können gemütlich und unverbindlich ein Online-Formular ausfüllen und alle wichtigen Details zu Ihrem Umzug eingeben.
            Wir berechnen ein Angebot und senden es Ihnen zu.</p>
          </MDBCardText>
          <router-link @click="OnlineFormClicked" alt="Angebot einholen" title="Angebot einholen" :to="{ name: 'requestform' }" class="mt-auto btn btn-primary btn-lg"><MDBIcon icon="clipboard-list" size="1x" /> <span> </span>Jetzt Angebot anfordern</router-link>
        </MDBCardBody>
      </MDBCard>
    </div>
  </div>
  <div class="row d-flex">
    <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard text="center">
        <MDBCardBody v-once  class="flex-column d-flex">
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Umzug Bayern App auf Ihrem Smartphone</MDBCardTitle>
          <MDBCardText v-once>
            Lassen Sie sich direkt auf Ihrem Smartphone ein Angebot errechnen.
            Sie erhalten kostenlos und unverbindlich ein Angebot.
            Der Umzug ist nur einen Klick entfernt.
            Eine Checkliste hilft Ihnen dabei, den Überblick nicht zu verlieren.
            Direkt im Google PlayStore oder Apple App Store herunterladen.
          </MDBCardText>
          <div class="row">
            <div class="col-6 my-auto">
              <a @click="AppleStoreClicked" target="_blank" title="Umzug Bayern App im Apple App Store" href='https://apps.apple.com/us/app/umzug-bayern/id1474660266'><img class="w-100" alt='Jetzt im Apple App Store' src='../assets/AppleAppStore.svg' title="Umzug Bayern App im Google PlayStore" loading="lazy"/></a>
            </div>
            <div class="col-6 my-auto">
              <a @click="PlayStoreClicked" target="_blank" title="Umzug Bayern App im Google PlayStore" href='https://play.google.com/store/apps/details?id=com.umzug_bayern.app&hl=gsw&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img class="w-100" alt='Jetzt bei Google Play' src='../assets/PlayStore_sm.webp' title="Umzug Bayern App im Google PlayStore" loading="lazy"/></a>
            </div>
          </div>
        </MDBCardBody>
      </MDBCard>
    </div>
    <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard text="center">
        <MDBCardBody v-once  class="flex-column d-flex">
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Schreiben Sie uns per WhatsApp</MDBCardTitle>
          <MDBCardText v-once>
            Schnell und unkompliziert. Schreiben Sie uns per WhatsApp.
            So können wir alle wichtigen Details besprechen.
            Darüber hinaus können Sie uns auch Fotos zuschicken, damit wir den Aufwand besser schätzen können.
          </MDBCardText>
          <MDBBtn class="mt-auto" @click="WhatsAppClicked" tag="a" target="_blank" title="Per WhatsApp shcreiben" href="https://api.whatsapp.com/send?phone=4915730438580" color="primary" size="lg"><MDBIcon icon="mobile" size="1x" /> <span> </span>Jetzt per WhatsApp schreiben</MDBBtn>
        </MDBCardBody>
      </MDBCard>
    </div>
  </div>
  <div class="row text-center mt-4">
    <div class="col-12">
      <h2>Unsere Leistungen für Ihren Umzug oder Entrümpelung im Überblick</h2>
    </div>
  </div>
  <div class="row d-flex">
    <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard v-once>
        <MDBCardImg v-once
          :src="require('@/assets/umzug-transporter-von-umzug-bayern.webp')"
          top
          alt="Professionelle Umzüge, Entrümpelungen und Transporte in München und Bayern"
          title="Professionelle Umzüge, Entrümpelungen und Transporte in München und Bayern"
          loading="lazy"
        />
        <MDBCardBody v-once >
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Professionelle Umzüge und Transporte</MDBCardTitle>
          <MDBCardText v-once>
            <p>Wir, das Umzug Bayern Team, sind Ihr Transport- und Umzugsunternehmen in München, rund um die Landeshauptstadt München und der Region in Oberbayern. Jahrelange Erfahrung in den Bereichen <i>Umzug</i> und Entrümpelung sowie kompetente und hilfsbereite Mitarbeiter und Mitarbeiterinnen zeichnen unser Umzugsunternehmen aus. Wir arbeiten unaufhörlich daran, Ihre Anforderungen und Wünsche mit einem Übermaß an Zuverlässigkeit, Professionalität, Diskretion, Effizienz und Freundlichkeit zu erfüllen. Schauen Sie sich gerne auf unserer Internetpräsenz um und überzeugen Sie sich von unserem breiten Angebotsspektrum, welches Ihnen nach terminlicher Absprache vollumfänglich zur Verfügung steht. Wir organisieren Umzüge im näheren Umfeld, können aber auch dann für Sie aktiv werden, wenn ein Fernumzug geplant wird. Wenn Sie einen Umzug planen, dann sind wir der richtige Ansprechpartner für Sie.
            </p>
            <p>Kontaktieren Sie uns und wir erstellen Ihnen ein ganz persönliches Angebot für Ihren Umzug!</p>
            <ul class="list-unstyled">

              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Privatumzüge" :to="{ name: 'privatumzuege' }">Privatumzüge</router-link></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Firmenumzüge" :to="{ name: 'firmenumzuege' }">Firmenumzüge</router-link></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Seniorenumzüge" :to="{ name: 'seniorenumzuege' }">Seniorenumzüge</router-link></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Studentenumzüge" :to="{ name: 'studentenumzug' }">Studentenumzüge</router-link></li>
            </ul>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </div>
    <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard v-once>
        <MDBCardImg v-once
          :src="require('@/assets/entruempelung-entsorgung-räumung.webp')"
          top
          alt="Entrümpelungen, Entsorgungen und Räumungen von Umzug Bayern"
          title="Entrümpelungen, Entsorgungen und Räumungen von Umzug Bayern"
          loading="lazy"
        />
        <MDBCardBody v-once >
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Entrümpelungen und Entsorgungen</MDBCardTitle>
          <MDBCardText v-once>
            <p>
              Umzug-Bayern ist Ihr Partner mit 16-jähriger Erfahrung, welcher stets kompetent und korrekt für Sie arbeitet und Dienstleistungen wie Auflösungen und Entrümpelungen
               aller Art für Sie übernimmt. Wir agieren dabei durchgehend diskret, effizient, professionell und
               zuverlässig. Wir achten dabei vollumfänglich auf unsere Kunden und Kundinnen und zeichnen uns demnach durch ein hohes Maß an Freundlichkeit
               und Hilfsbereitschaft aus. Der Umfang spielt keine Rolle, da wir sowohl kleinere Entsorgungen
             als auch umfangreiche Entrümpelungen gerne für Sie übernehmen. Sie können uns gleichermaßen für stark vernachlässigte Wohnstätten beauftragen.
             Wir übernehmen dabei unter anderem die Verantwortung für den Abbau und Abtransport von Möbeln und anderen Gebrauchsgegenständen
             aus der Wohneinheit. Selbstverständlich ist es auch möglich, einzelne Zimmer wie zum Beispiel Keller oder Dachböden zu entrümpeln.
             Des Weiteren befördern wir das Transportgut für Sie zu einem Wertstoffhof, damit alle Objekte fachmännisch und
             umweltgerecht entsorgt werden können.
            </p>
            <p>Unsere Leistungen für Sie im Überblick</p>
            <ul class="list-unstyled">
              <li><span class="h6">Wohneinheitsentrümpelungen</span></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Entrümpelungen" :to="{ name: 'entruempelung' }">Entrümpelungen</router-link></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Hausentrümpelung" :to="{ name: 'hausentruempelung' }">Hausentrümpelung</router-link></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Wohnungsentrümpelung und Wohnungsauflösung" :to="{ name: 'wohnungsentruempelung' }">Wohnungsentrümpelung und Wohnungsauflösung</router-link></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Messie-Wohnungsentrümpelung und Entkernung" :to="{ name: 'wohnungsentruempelung' }">Messie-Wohnungsentrümpelung und Entkernung</router-link></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Entkernung" :to="{ name: 'entkernung' }">Entkernung</router-link></li>

              <li><span class="h6">Raumentrümpelungen</span></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Kellerentrümpelung" :to="{ name: 'kellerentruempelung' }">Kellerentrümpelung</router-link></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Dachbodenentrümpelung" :to="{ name: 'dachbodenentruempelung' }">Dachbodenentrümpelung</router-link></li>
              <li><span class="h6">Gewerbliche Entrümpelungen</span></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Firmenentrümpelung" :to="{ name: 'firmenentruempelung' }">Firmenentrümpelung und Gewerbeentrümpelung</router-link></li>
              <li><MDBIcon icon="angle-double-right" iconStyle="fas" class="text-primary me-1"/><router-link title="Lagerentrümpelung" :to="{ name: 'lagerentruempelung' }">Lagerentrümpelung</router-link></li>
            </ul>
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
    </div>
  </div>
  </div>
  <div class="my-4"><RatingBanner/></div>
  <div class=" container">
    <div class="row">

    <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard v-once>
        <MDBCardImg v-once
          :src="require('@/assets/umzug-muenchen-verpackungsmaterial-kartons-umzugskartons.webp')"
          top
          alt="Verpackungsmaterial und Halteverbotsschilder"
          title="Verpackungsmaterial und Halteverbotsschilder"
          loading="lazy"
        />
        <MDBCardBody v-once  class="flex-column d-flex">
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Verpackungsmaterial und Deutschlandweite Halteverbotszonen</MDBCardTitle>
          <MDBCardText v-once>
            <p>
              Auf Ihren Wunsch hin besorgen wir alle notwendigen Utensilien wie <router-link title="Umzugskartons und Verpackungsmaterial" :to="{ name: 'umzugsmaterialien' }">Umzugskartons und Verpackungsmaterial</router-link>, damit der Transport unkompliziert und professionell erfolgt. Unsere erfahrenen und sorgfältig agierenden Möbelpacker kümmern sich um einen reibungslosen Ablauf. Selbstverständlich kümmern wir uns um einen fachmännischen und sicheren Transport Ihres Eigentums. Ein gewissenhafter Umgang mit Ihren Besitztümern hat bei uns oberste Priorität. Deshalb wird Ihr Transportgut von uns versichert und ist somit vollumfänglich in unseren Transportfahrzeugen geschützt.
            </p>
            <p>
              Zudem beantragen wir bei Bedarf Deutschlandweit Halteverbotsschilder, damit die Verladung und Entladung der Transportfahrzeuge reibungslos abläuft. Dieser Service beinhaltet sowohl die behördliche Genehmigung einer oder mehrerer Halteverbotszonen als auch das Aufstellen der Halteverbotsschilder. Um eine eventuell auftretende Kommunikationssituation mit der Polizei oder dem Abschleppdienst vor Ort kümmern wir uns ebenfalls. Bitte haben Sie Verständnis dafür, dass wir auf unserer Internetseite keine konkrete Preisliste veröffentlichen können. Aufgrund regionaler Unterschiede können Preise mitunter voneinander abweichen. Wir stehen jedoch für ein sehr hohes Maß an Transparenz, deshalb kontaktieren Sie uns gerne jederzeit, wenn Sie Fragen haben.
            </p>
          </MDBCardText>
            <router-link @click="UmzugsmaterialienClicked" alt="Umzugsmaterialien kaufen" title="Umzugsmaterialien kaufen" :to="{ name: 'umzugsmaterialien' }" class="mt-auto btn btn-primary btn-lg"><MDBIcon icon="box" size="1x" class="me-2" />Umzugsmaterialien</router-link>

        </MDBCardBody>
      </MDBCard>
    </div>
    <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard v-once>
        <MDBCardImg v-once
          :src="require('@/assets/umzug_bayern_tatortreinigung_zertifikat_2_sm.webp')"
          top
          alt="Tatortreiniger München"
          title="Tatortreiniger München"
          loading="lazy"
        />
        <MDBCardBody v-once  class="flex-column d-flex">
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Tatortreinigung</MDBCardTitle>
          <MDBCardText v-once>
            <p>Als Unternehmen aus München bieten wir bei Umzug Bayern eine umfassende Tatortreinigung an, die auf die Bedürfnisse unserer Kunden zugeschnitten ist. Wir wissen, dass es schwierig sein kann, mit einem solch emotional belastenden Ereignis umzugehen und bieten daher einen professionellen und diskreten Service an. Unsere zertifizierten Tatortreiniger verfügen über das notwendige Fachwissen und die Ausrüstung, um jeden Tatort gründlich zu reinigen und alle potenziell gefährlichen Rückstände zu beseitigen. Wir sind uns bewusst, dass jede Situation einzigartig ist, und bieten daher eine individuelle Beratung an, um sicherzustellen, dass unsere Kunden genau das bekommen, was sie brauchen. Bei Umzug Bayern können sich unsere Kunden darauf verlassen, dass sie eine schnelle, effektive und professionelle Tatortreinigung erhalten.</p>
          </MDBCardText>
          <router-link alt="Tatortreinigung" title="Tatortreinigung" :to="{ name: 'tatortreinigung' }" class="mt-auto btn btn-primary btn-lg mt-auto"><MDBIcon icon="biohazard" size="1x" class="me-2" />Tatortreinigung</router-link>
        </MDBCardBody>
      </MDBCard>
    </div>
    <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard v-once>
        <MDBCardImg v-once
          :src="require('@/assets/pexels-ketut-subiyanto-4245917_sm.webp')"
          top
          alt="Umzugstipps"
          title="Umzugstipps"
          loading="lazy"
        />
        <MDBCardBody v-once  class="flex-column d-flex">
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Umzugstipps</MDBCardTitle>
          <MDBCardText v-once>
            <p>Umzug, Stress und kein Ende in Sicht? Ein Umzug ist in den seltensten Fällen eine entspannte Angelegenheit. Mit unseren Umzug-Tipps können Sie sich optimal auf den großen Tag vorbereiten.</p>
            <p>Auf der folgenden Seite haben wir Ihnen einige Umzugstipps zusammengestellt, um Sie bei Ihrem Umzug zu untersützen.</p>
          </MDBCardText>
          <router-link @click="UmzugstippsClicked" alt="Umzug Tipps" title="Umzug Tipps" :to="{ name: 'umzugstipps' }" class="mt-auto btn btn-primary btn-lg mt-auto"><MDBIcon icon="info" size="1x" /> <span> </span>Umzugstipps</router-link>
        </MDBCardBody>
      </MDBCard>
    </div>
    <div class="col-12 col-md-6 mt-4 d-flex">
      <MDBCard v-once>
        <MDBCardImg v-once
          :src="require('@/assets/pexels-ketut-subiyanto-4246012.webp')"
          top
          alt="Umzugscheckliste"
          title="Umzugscheckliste"
          loading="lazy"
        />
        <MDBCardBody v-once  class="flex-column d-flex">
          <MDBCardTitle v-once  tag="h2" class="text-uppercase h3">Umzugscheckliste</MDBCardTitle>
          <MDBCardText v-once>
            <p>Um einen Umzug erfolgreich zu meistern, bedarf es einer sehr guten Planung. Mit unserer Umzugscheckliste könne Sie sich optimal auf den Umzug vorbereiten.</p>
            <p>Auf der folgenden Seite haben wir Ihnen eine interaktive Umzugscheckliste mit den wichtigsten Aufgaben zusammengestellt, um Sie bei der Planung Ihres Umzuges optimal zu unterstützen.</p>
          </MDBCardText>
          <router-link @click="ChecklisteClicked" alt="Umzug Checkliste" title="Umzug Checkliste" :to="{ name: 'checkliste' }" class="mt-auto btn btn-primary btn-lg mt-auto"><MDBIcon icon="clipboard-list" size="1x" /> <span> </span>Umzugscheckliste</router-link>
        </MDBCardBody>
      </MDBCard>
    </div>

    <div class="row d-flex">
      <div class="col-12 col-md-12 mt-4 d-flex px-4">
        <ImageText
        v-once
        textside="left"
        :imgsrc="require('@/assets/umzug_bayern_transporter_münchen.webp')"
        imgalt="umzug bayern transporter münchen"
        >
        <h2>Schreiben Sie uns!</h2>
        <ContactForm/>
        </ImageText>
      </div>
    </div>

    <div class="col-12 col-md-12 mt-4 d-flex">
      <ImageText
      v-once
      textside="right"
      :imgsrc="require('@/assets/20240710/umzug-bayern-umzugshelfer.webp')"
      imgalt="Freundliche und professionelle Umzugshelfer"
      >
      <h2>Freundlich und professionell für Sie am Werk</h2>
      <p>Herzlich Willkommen bei Umzug Bayern - Ihrem professionellen <i>Umzugsunternehmen in der Nähe</i> von München. Wir sind stolz darauf, Ihnen unseren herausragenden Umzugsservice anbieten zu können, der Ihnen ein nahtloses, stressfreies und angenehmes Umzugserlebnis garantiert.</p>
        <p>Wenn Sie jemals einen Umzug in München geplant haben, wissen Sie, wie anstrengend und stressig er sein kann. Aber keine Sorge, wir sind hier, um Ihnen zu helfen! Mit unserer jahrelangen Erfahrung in der Branche haben wir zahlreiche Umzüge in und um München erfolgreich durchgeführt.</p>
        <p>Bei Umzug Bayern legen wir großen Wert auf Zuverlässigkeit, Sauberkeit, Schnelligkeit und freundliche Mitarbeiter. Unser Team besteht aus erfahrenen Umzugsprofis, die Ihren Umzug mit Leichtigkeit durchführen werden. Wir sorgen dafür, dass Ihre Möbel und Ihr Eigentum sicher und unbeschädigt an Ihrem neuen Zielort ankommen.</p>
        <p>Wir bieten eine Vielzahl von Umzugsservices an, darunter Verpackung, Demontage und Montage, sowie Ein- und Auspacken, um sicherzustellen, dass Ihr Umzug reibungslos verläuft. Wir bieten auch maßgeschneiderte Umzugslösungen, die auf Ihre individuellen Bedürfnisse zugeschnitten sind.</p>
        <p>Wir sind stolz darauf, unseren Kunden einen hervorragenden Kundenservice zu bieten. Unser freundliches und professionelles Team wird Ihnen jederzeit zur Verfügung stehen, um alle Ihre Fragen zu beantworten und Ihnen bei allen Umzugsbedürfnissen zu helfen.</p>
        <p>Wenn Sie also zuverlässige und professionelle Umzugshelfer aus München suchen, dann zögern Sie nicht, uns zu kontaktieren. Wir freuen uns darauf, Ihnen bei Ihrem Umzug zu helfen!</p>
        <router-link @click="OnlineFormClicked" alt="Angebot einholen" title="Angebot einholen" :to="{ name: 'requestform' }" class="mt-auto btn btn-primary btn-lg btn-block"><MDBIcon icon="clipboard-list" size="1x" /> <span> </span>Jetzt Angebot anfordern</router-link>
      </ImageText>
    </div>
    <div class="row text-center mt-4">
      <div class="col-12">
        <h2>Kundenzufriedenheit ist uns sehr wichtig</h2>
      </div>
    </div>
  <ReviewsCarousel/>
  <div class="row text-center my-4">
    <div class="col-12">
      <h2>Unser Blog für den perfekten Umzug</h2>
    </div>
  </div>
  <BlogPreview :count="4"/>
  <router-link alt="Umzugsblog / Umzugsratgeber" title="Umzugsblog Umzugsratgeber" :to="{ name: 'blog' }" class="mt-auto btn btn-primary btn-lg btn-block">Mehr Blogartikel zum Thema Umzug lesen!</router-link>
  </div>
</div>
<p class="text-center"><SocialShareButtons :share_url="siteUrl" share_text="Umzug Bayern - Umzüge, Entrümpelungen und Transporte in Bayern" /></p>
</div>
</template>

<script>
import { MDBCard, MDBCardImg, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon } from 'mdb-vue-ui-kit'
import SocialShareButtons from '@/components/SocialShareButtons.vue'
import ContactForm from '@/components/ContactForm.vue'
import HeadImage from '@/components/HeadImage.vue'
import ReviewsCarousel from '@/components/ReviewsCarousel.vue'
import ImageText from '@/components/ImageText.vue'
import BlogPreview from '@/components/BlogPreview.vue'
import RatingBanner from '@/components/RatingBanner.vue'
import { useMeta } from 'vue-meta'
import { useRoute } from 'vue-router'

export default {
  name: 'Home',
  components: {
    MDBCard, MDBCardImg, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon,
    SocialShareButtons,
    ContactForm,
    HeadImage,
    ReviewsCarousel,
    ImageText,
    BlogPreview,
    RatingBanner
  },
  methods: {
     PhoneCallClicked() {
       this.$gtag.event('PhoneCall', {
         'event_category' : 'Interaction',
         'event_label' : 'phone call link clicked on main page'
       })
     },
     OnlineFormClicked() {
       this.$gtag.event('OnlineForm', {
         'event_category' : 'Interaction',
         'event_label' : 'online form link clicked on main page'
       })
     },
     PlayStoreClicked() {
       this.$gtag.event('PlayStore', {
         'event_category' : 'Interaction',
         'event_label' : 'play store link clicked on main page'
       })
     },
     AppleStoreClicked() {
       this.$gtag.event('AppleStore', {
         'event_category' : 'Interaction',
         'event_label' : 'apple store link clicked on main page'
       })
     },
     WhatsAppClicked() {
       this.$gtag.event('WhatsApp', {
         'event_category' : 'Interaction',
         'event_label' : 'WhatsApp link clicked on main page'
       })
     },
     UmzugstippsClicked() {
       this.$gtag.event('Umzugstipps', {
         'event_category' : 'Interaction',
         'event_label' : 'umzugstipps link clicked on main page'
       })
     },
     ChecklisteClicked() {
       this.$gtag.event('Checkliste', {
         'event_category' : 'Interaction',
         'event_label' : 'checkliste link clicked on main page'
       })
     },
     UmzugsmaterialienClicked() {
       this.$gtag.event('Umzugsmaterialien', {
         'event_category' : 'Interaction',
         'event_label' : 'umzugsmaterialien link clicked on main page'
       })
     },
     UmzugskostenrechnerClicked() {
       this.$gtag.event('Umzugskostenrechner', {
         'event_category' : 'Interaction',
         'event_label' : 'umzugskostenrechner link clicked on main page'
       })
     }
  },
  computed: {
    siteUrl() {
      return process.env.VUE_APP_URL
    }
  },
  setup() {
    const description = "Umzüge in München und ganz Bayern zu günstigen Preisen. Alles was Sie rund um den Umzug in Bayern wissen müssen."
    var title = 'Umzug Bayern - Umzüge in München und Umgebung'
    var keywords = "Umzugsunternehmen, Umzugsangebote, Umzugshelfer, Umziehen, Umzugstipps, Umzüge, München"
    var route = useRoute()
    var url = process.env.VUE_APP_URL + route.path
    useMeta({
      title: title,
      description: description,
      keywords: keywords,
      link: {
        rel: "canonical",
        href: url
      },
      og: {
        type: "website",
        title: title,
        url: url,
        description: description,
        site_name: process.env.VUE_APP_NAME
      },
      twitter: {
        card: "summary",
        title: title,
        url: url,
        description: description,
        label1: "Geschätzte Lesezeit",
        data1: "10 Minuten"
      },
      robots: "index, follow"
    })
  }
}
</script>

<style scoped>

.homecontainer{
  background-color: #e3e3e3 !important;
}


.carousel-home {
  display: block;
  position: relative;
  object-fit: cover;
  height: 90vh !important;

}

.carousel-item > img {
  height: 90vh !important;
  object-fit: cover;
  object-position: center top;
}


.carousel-caption {
  background-color:rgba(255, 255, 255, 0.7);
}

.carousel-caption > p {
  font-size: 1em !important;
    opacity: 1 !important;
}

.carousel-caption > h5 {
  font-size: 1.5em !important;
    opacity: 1 !important;
}



.home {
  background-color: white;
}
</style>
